<template>
  <component :is="taskData === undefined ? 'div' : 'b-card'">
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="taskData === undefined">
      <h4 class="alert-heading">{{ $t("message.errorLoadingData") }}</h4>
      <div class="alert-body">
        {{ $t("message.errorTask") }}
        <b-link class="alert-link" :to="{ name: 'apps-team-list' }">
          {{ $t("message.listOfTask") }}
        </b-link>
        {{ $t("message.forOtherTask") }}
      </div>
    </b-alert>

    <template v-if="taskData">
      <b-card>
        <h2 class="mb-2">{{ $t("message.tasks.viewTask") }}</h2>

        <b-overlay
          variant="transparent"
          opacity="1.00"
          blur="6px"
          :show="showOverlay"
          rounded="sm"
          no-wrap
        ></b-overlay>

        <b-row>
          <b-col md="8">
            <b-form-group label-for="nameTasks">
              <template v-slot:label>
                {{ $t("message.tableHeader.name") }}
              </template>
              <b-form-input
                v-model="taskData.subject"
                label="name"
                label-for="nameTasks"
                disabled
              />
            </b-form-group>
          </b-col>

          <b-col md="2">
            <b-form-group label="Ínicio " label-for="createdDate">
              <template v-slot:label>
                {{ $t("message.date.start") }}
              </template>
              <b-form-datepicker
                id="created-date"
                v-model="taskData.startDate"
                class="form-control"
                :locale="$i18n.locale"
                :date-format-options="{
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                }"
                disabled
              />
            </b-form-group>
          </b-col>

          <b-col md="2">
            <b-form-group label="Fim" label-for="endDate">
              <template v-slot:label>
                {{ $t("message.date.end") }}
              </template>
              <b-form-datepicker
                id="created-date"
                v-model="taskData.endDate"
                class="form-control"
                :locale="$i18n.locale"
                :date-format-options="{
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                }"
                disabled
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12">
            <b-form-group label="Descrição" label-for="descriptionTasks">
              <template v-slot:label>
                {{ $t("message.projects.description") }}
              </template>
              <b-alert variant="secondary" show>
                <div class="alert-body">
                  <span v-html="taskData.description"></span>
                </div>
              </b-alert>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="2">
            <b-form-group label="Tempo estimado" label-for="deadlineTasks">
              <template v-slot:label>
                {{ $t("message.projects.estimatedTime") }}
              </template>
              <b-form-input
                input-id="estimatedTime"
                id="number"
                type="number"
                label="estimatedTime"
                label-for="deadlineTasks"
                v-model="taskData.estimateTime"
                disabled
              />
            </b-form-group>
          </b-col>

          <b-col md="2">
            <b-form-group label="Prioridade" label-for="projectId">
              <template v-slot:label>
                {{ $t("message.tasks.priority") }}
              </template>
              <v-select
                v-model="taskData.priority"
                :options="taskPriorityOptions"
                :reduce="(val) => val.value"
                :clearable="false"
                input-id="task-status"
                disabled
              />
            </b-form-group>
          </b-col>

          <b-col md="8">
            <b-form-group label="Membros" label-for="userId">
              <template v-slot:label>
                {{ $t("message.team.members") }}
              </template>
              <v-select
                multiple
                v-model="taskData.members"
                :options="userOptions"
                :reduce="(val) => val.value"
                :clearable="false"
                input-id="task-users"
                disabled
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="4">
            <b-form-group label="Projeto" label-for="projectId">
              <template v-slot:label>
                {{ $t("message.projects.project") }}
                <span
                  class="cursor-pointer"
                  @click="openProjectDetails(taskData.projectId)"
                >
                  <IconLinkInternal size="12"/>
                </span>
                <!-- <feather-icon
                  icon="ExternalLinkIcon"
                  size="14"
                  class="cursor-pointer"
                  @click="openProjectDetails(taskData.projectId)"
                /> -->
              </template>
              <v-select
                :options="projectOptions"
                :reduce="(val) => val.value"
                :clearable="false"
                v-model="taskData.projectId"
                input-id="task-projects"
                disabled
              />
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group label="Estado" label-for="projectId">
              <template v-slot:label>
                {{ $t("message.tableHeader.status") }}
              </template>
              <v-select
                v-model="taskData.status"
                :options="taskStatusOptions"
                :reduce="(val) => val.value"
                :clearable="false"
                input-id="task-status"
                disabled
              />
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group label="Tipo" label-for="projectId">
              <template v-slot:label>
                {{ $t("message.tasks.type") }}
              </template>
              <v-select
                v-model="taskData.type"
                :options="taskTypeOptions"
                :reduce="(val) => val.value"
                :clearable="false"
                input-id="task-status"
                disabled
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col md="12">
            <b-form-group label="Tipo" label-for="projectId">
              <template v-slot:label>
                {{ $t("Progress") }} {{ taskData.percentage }}%
              </template>
              <input
                class="slider2"
                v-model="taskData.percentage"
                type="range"
                id="done"
                step="1"
                v-bind:style="gradientColorProgressBar()"
                disabled
              />
            </b-form-group>
          </b-col>
        </b-row>

        <!-- Spacer -->
        <hr class="invoice-spacing" />

        <br />
        <b-row>
          <b-col
            style="max-width: 200px; !important"
            v-for="item in filesData"
            :key="item.id"
          >
            <b-card
              text-variant="white"
              class="text-center"
              bg-variant="primary"
            >
              <feather-icon size="40" icon="FileIcon" />

              <div class="truncate">
                <br />
                <span>{{ item.name }}</span>
              </div>
              <br />
              <b-row>
                <b-col md="12"
                  ><b-button
                    size="sm"
                    @click="downloadFile(item.id, item.name)"
                    v-if="restrictions('task_file_download')"
                    ><feather-icon size="20" icon="DownloadCloudIcon"
                  /></b-button>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-card>
    </template>
    <b-row>
      <b-col>
        <div class="text-right mr-2 mb-2 rButton">
          <b-button
            :to="{ name: 'apps-task-edit', params: { id: taskData.id } }"
            variant="primary"
            v-if="restrictions('button_edit_task') && taskData != null"
          >
            {{ $t("message.buttons.edit") }}
          </b-button>
        </div>
      </b-col>
    </b-row>
  </component>
</template>

<script>
import {
  BTab,
  BTabs,
  BCard,
  BAlert,
  BLink,
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BFormSelect,
  BFormTextarea,
  BCardText,
  BFormSpinbutton,
  BProgress,
  BProgressBar,
  BFormDatepicker,
  BCardBody,
  BOverlay,
} from "bootstrap-vue";
import { ref } from "@vue/composition-api";
import axios from "@axios";
import URL_API from "@core/utils/env";
import getHeader from "@core/utils/auth";
import vSelect from "vue-select";
import router from "@/router";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import flatPickr from "vue-flatpickr-component";
import AccessControl from "@core/utils/access-control";
import store from "@/store";
import download from "downloadjs";
import IconLinkInternal from "@core/components/iconLink/IconLinkInternal.vue"
export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BFormSelect,
    vSelect,
    BFormTextarea,
    BCardText,
    flatPickr,
    BFormSpinbutton,
    BProgress,
    BProgressBar,
    BFormDatepicker,
    BCardBody,
    BOverlay,
    IconLinkInternal
  },

  data() {
    return {
      //Select
      projectOptions: [],
      userOptions: [],
      taskStatusOptions: [],
      taskTypeOptions: [],
      taskPriorityOptions: [],
      userData: store.state.user.userData,

      paramData: store.state.user.paramData,
      showOverlay: false,

      fileFormats: "",
      fileMaxSize: 0,
      file: null,
      filesData: [],
      selectedFileId: "",
      selectedFileName: "",
    };
  },

  async created() {
    this.showOverlay = true;
    var projectList = [];
    var projectId = [];
    await axios
      .get(`${URL_API}project/select_list`, {
        headers: getHeader(this.userData),
      })
      .then((response) => {
        for (let i = 0; i < response.data.length; i++) {
          const element = response.data[i].name;
          const id = response.data[i].id;
          projectList.push(element);
          projectId.push(id);
          this.projectOptions.push({
            label: projectList[i],
            value: projectId[i],
          });
        }
      });

    var userList = [];
    var userId = [];
    await axios
      .get(`${URL_API}user/select_list`, { headers: getHeader(this.userData) })
      .then((response) => {
        for (let i = 0; i < response.data.length; i++) {
          const element = response.data[i].email;
          const id = response.data[i].id;
          userList.push(element);
          userId.push(id);
          this.userOptions.push({ label: userList[i], value: userId[i] });
        }
      });

    var optionsList = [];
    await axios
      .get(`${URL_API}task_status`, { headers: getHeader(this.userData) })
      .then((response) => {
        for (let i = 0; i < response.data.length; i++) {
          const element = response.data[i].status;
          optionsList.push(element);
          this.taskStatusOptions.push({
            label: optionsList[i],
            value: optionsList[i],
          });
        }
      });

    var typeList = [];
    await axios
      .get(`${URL_API}task_type`, { headers: getHeader(this.userData) })
      .then((response) => {
        for (let i = 0; i < response.data.length; i++) {
          const element = response.data[i].type;
          typeList.push(element);
          this.taskTypeOptions.push({ label: typeList[i], value: typeList[i] });
        }
      });

    var priorityList = [];
    await axios
      .get(`${URL_API}task_priority`, { headers: getHeader(this.userData) })
      .then((response) => {
        for (let i = 0; i < response.data.length; i++) {
          const element = response.data[i].priority;
          priorityList.push(element);
          this.taskPriorityOptions.push({
            label: priorityList[i],
            value: priorityList[i],
          });
        }
      });

    await this.getTaskFilesData();
    setTimeout(() => {
      this.showOverlay = false;
    }, 1000)
  },

  methods: {
    restrictions(value) {
      return AccessControl(value);
    },

    downloadFile(id, name) {
      axios({
        method: "get",
        url: `${URL_API}file/download/${id}`,
        headers: getHeader(this.userData),
        responseType: "blob",
      }).then((response) => {
        if (response.data.size === 0) {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: this.$i18n.t("Error"),
              icon: "XIcon",
              variant: "warning",
              text: this.$i18n.t("offline"),
            },
          });
        } else {
          const content = response.headers["content-type"];
          download(response.data, name, content);
        }
      });
    },

    async getTaskFilesData() {
      this.files = [];
      await axios({
        method: "get",
        url: `${URL_API}file/${router.currentRoute.params.id}`,
        headers: getHeader(this.userData),
      }).then((response) => {
        this.filesData = response.data;
      });
    },

    openProjectDetails(projectId) {
      this.$router.push({
        name: "apps-projects-details",
        params: { id: projectId },
      });
    },

    gradientColorProgressBar() {
      return `background: linear-gradient(90deg, currentColor ${this.taskData.percentage}%, rgba(0,0,0,0.1) ${this.taskData.percentage}%); animationDelay: -${this.taskData.percentage}s; !important`;
    },
  },

  setup() {
    const taskData = ref(null);

    axios({
      method: "get",
      url: `${URL_API}task/${router.currentRoute.params.id}`,
      headers: getHeader(store.state.user.userData),
    })
      .then((response) => {
        taskData.value = response.data;
      })
      .catch((error) => {
        if (error.response.status === 404) {
          taskData.value = undefined;
        }
      });

    return {
      taskData,
      vSelect,
    };
  },
};
</script>

<style>
.slidecontainer {
  width: 100%;
}

.slider2 {
  -webkit-appearance: none;
  width: 100%;
  height: 25px;
  outline: none;
  animation: color-range 100s linear forwards paused;
  background: linear-gradient(90deg, currentColor 50%, rgba(0, 0, 0, 0.1) 50%);
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.slider2::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  background: #313a46;
  cursor: default;
}

.slider2::-moz-range-thumb {
  width: 25px;
  height: 25px;
  background: #04aa6d;
  cursor: default;
}

@keyframes color-range {
  0% {
    color: #f57070;
  }
  50% {
    color: #f5f570;
  }
  100% {
    color: #70f570;
  }
}
</style>
